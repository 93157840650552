import React, { useState, useEffect } from "react";
import Axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
	const [siteSetup, setSitesetupData] = useState([]);
	let isItemSubmit = false;
	
	const [isLoading, setisLoading] = useState(false);
	const [ItemFormErrors, setItemFormErrors] = useState([]);
	const [itemState, itemSetState] = React.useState({
		contactName: "",
		contactEmail: "",
		contactPhone: "",
		contactComment: ""
	});
	const handleItemChange = ({ target })=>{
		itemSetState({ ...itemState, [target.name]: target.value});
	};
	const submitContact = e => {		
		e.preventDefault();
		itemValidate(itemState);
		if(isItemSubmit){
			setisLoading(true);
			Axios.post(`/contactinsert`, itemState).then(response => {
				toast('Thanks! We will contact you shortly.');
				itemSetState({ ...itemState, contactName: '',contactEmail: '',contactPhone: '',contactComment: ''});
				setisLoading(false);
			});
		}
	};
	const itemValidate = (values) => {
		const errors = {};
		if (!values.contactName) {
			isItemSubmit = false;
			errors.contactName = "Name field is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.contactEmail) {
			isItemSubmit = false;
			errors.contactEmail = "Email is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.contactPhone) {
			isItemSubmit = false;
			errors.contactPhone = "Phone no is required!";
		}else{
			isItemSubmit = true;
		}
		if (!values.contactComment) {
			isItemSubmit = false;
			errors.contactComment = "Message is required!";
		}else{
			isItemSubmit = true;
		}
		setItemFormErrors(errors);
		return isItemSubmit;
	}
	useEffect(()=> {
		Axios.get('/sitesetup/').then((response)=>{
			//console.log(response.data);
			setSitesetupData(response.data[0]);
		});
	}, []);
	return <>
		<section className="fullstackTop">
		<ToastContainer />
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center"><h2>Contact Us</h2></div>
					</div>
					<div className="col-md-8 col-sm-8 col-12 contactLeft">						
						<div className="form-group">
							<label>Name</label>
							<input type="text" name="contactName" onChange={handleItemChange} className="form-control" placeholder="Name"/>
							<div className="errorMesg">{ItemFormErrors.contactName}</div>
						</div>
						<div className="form-group">
							<label>Email address</label>
							<input type="email" name="contactEmail" onChange={handleItemChange} className="form-control" placeholder="name@example.com"/>
							<div className="errorMesg">{ItemFormErrors.contactEmail}</div>
						</div>
						<div className="form-group">
							<label>Phone No</label>
							<input type="text" name="contactPhone" onChange={handleItemChange} className="form-control" placeholder="Phone No"/>
							<div className="errorMesg">{ItemFormErrors.contactPhone}</div>
						</div>
						<div className="form-group">
							<label>Message</label>
							<textarea name="contactComment" onChange={handleItemChange} className="form-control" rows="3"></textarea>
							<div className="errorMesg">{ItemFormErrors.contactComment}</div>
						</div>
						<div className="submitBtn">
							<button onClick={submitContact} className="btn btn-primary" disabled={isLoading}>
								{isLoading ? <div className="btnloader"></div> : 'Submit'}
							</button>
						</div>
					</div>
					<div className="col-md-4 col-sm-4 col-12 contactRight">
						<div className="contactUsRight">
							<p className="ourOffice"><i class="fas fa-globe-asia"></i>Our office</p>
							<p><i class="fas fa-home"></i><b>{siteSetup.site_name}</b><span>{siteSetup.location}</span></p>
							<p><i class="far fa-envelope"></i>{siteSetup.email}</p>
							<p><i class="fas fa-phone-alt"></i>{siteSetup.phone}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Contact;
